import React,{ useState, useEffect } from 'react';
import EventListView from './components/EventListView';
import './App.css';
import ParseWSDCEvents from './services/WSDCService';
import Event from './models/Event';
import { GetEventsForWSDCEvents, GetEventsForNonWSDCEvents } from './services/AnnualEventService';
import GroupEventsByMonth from './services/EventService';
import IsEventInRegion from './services/GeographyService';
import WSDCEvent from './models/WSDCEvent';
import NonWSDCEvent from './models/NonWSDCEvent';

function App() {
  const [wsdcEvents, setWsdcEvents] = useState<WSDCEvent[]>([]);
  const [nonWsdcEvents, setNonWsdcEvents] = useState<NonWSDCEvent[]>([]);
  const [events, setEvents] = useState<Event[]>([]);
  const [shouldProcessEvents, setShouldProcessEvents] = useState<boolean>(false);
  const [filter, setFilter] = useState<string>("All Events");

  useEffect(() => {
    var xhttp = new XMLHttpRequest();
    xhttp.onreadystatechange = function() {
        if (this.readyState === 4 && this.status === 200) {
          const parsedWsdcEvents = ParseWSDCEvents(xhttp.responseText);
          setWsdcEvents(parsedWsdcEvents);
          setShouldProcessEvents(true);
        }
    };
    xhttp.open("GET", "/events", true);
    xhttp.send();

    var xhttp2 = new XMLHttpRequest();
    xhttp2.onreadystatechange = function() {
        if (this.readyState === 4 && this.status === 200) {
          if(xhttp2.responseText) {
            const parsedNonWsdcEvents = JSON.parse(xhttp2.responseText);
            setNonWsdcEvents(parsedNonWsdcEvents);
            setShouldProcessEvents(true);  
          }
        }
    };
    xhttp2.open("GET", "/events/non-wsdc-events.json", true);
    xhttp2.send();
  }, []);

  if(shouldProcessEvents) {

    const wsdcEventsProcessed = GetEventsForWSDCEvents(wsdcEvents);
    const nonWsdcEventsProcessed = GetEventsForNonWSDCEvents(nonWsdcEvents);

    setEvents(wsdcEventsProcessed.concat(nonWsdcEventsProcessed));
    setShouldProcessEvents(false);
  }

  function updateFilter(event: React.ChangeEvent<HTMLSelectElement>) {
    setFilter(event.target.value);
  }

  const filteredEvents = events.filter((event) => {
    if(event.isOnHiatus)
    {
      //Omit events which are on hiatus from the list.
      return false;
    }
    if(!event.endDate)
    {
      console.log("No end date for event: " + event.name);
      return false;
    }
    const currentDate = new Date();
    const dayAfterEvent = new Date(event.endDate.getTime() + 86400000);
    if(!(dayAfterEvent > currentDate))
    {
      return false;
    }
    return IsEventInRegion(event, filter);
  });
  const eventGroups = GroupEventsByMonth(filteredEvents);

  let content = eventGroups.map((group) => {
    const eventsList = group.events.map((event) => {
      const key = `${event.name}-${event.startDate}`;
      return (
        <EventListView event={event} key={key} />
      );
    });
    const backgroundColor = group.name === "Happening Now" ? '#bde3ff' : undefined;
    return (
      <div key={group.name} style={{backgroundColor: backgroundColor}}>
        <h3>{group.name}</h3>
        <div className="flexbox">
          {eventsList}
        </div>
      </div>
    );
  });

  return (
    <div className="App">
      <div className="ListNavigation">
        <div style={{marginRight: '10px'}}>Filter Events:</div>
        <select onChange={updateFilter}>
          <option value="All Events">All Events</option>
          <option value="North America">North America</option>
          <option value="South America">South America</option>
          <option value="Europe">Europe</option>
          <option value="Asia">Asia</option>
          <option value="Oceania">Oceania</option>
          <option value="Southeast U.S.">Southeast U.S.</option>
          <option value="Northeast U.S.">Northeast U.S.</option>
          <option value="Central U.S.">Central U.S.</option>
          <option value="Southwest U.S.">Southwest U.S.</option>
          <option value="Northwest U.S.">Northwest U.S.</option>
        </select>
      </div>
      <div className="EventList">
        {content}
      </div>
    </div>
  );
}

export default App;