function GetDateDisplay(startDate: Date, endDate: Date) {
    const startDay = startDate.getDate();
    const endDay = endDate.getDate();
    const startMonth = getAbbreviatedMonth(startDate.getMonth());
    const endMonth = getAbbreviatedMonth(endDate.getMonth());
    const startYear = startDate.getFullYear();
    const endYear = endDate.getFullYear();

    if(startDate.getMonth() === endDate.getMonth())
    {
        return `${startMonth} ${startDay}-${endDay}, ${startYear}`;
    }
    if(startDate.getFullYear() === endDate.getFullYear())
    {
        return `${startMonth} ${startDay} - ${endMonth} ${endDay}, ${startYear}`;
    }
    return `${startMonth} ${startDay}, ${startYear} - ${endMonth} ${endDay}, ${endYear}`;
}

function getAbbreviatedMonth(monthNumber : number)
{
    switch(monthNumber)
    {
        case 0:
            return "Jan";
        case 1:
            return "Feb";
        case 2:
            return "Mar";
        case 3:
            return "Apr";
        case 4:
            return "May";
        case 5:
            return "Jun";
        case 6:
            return "Jul";
        case 7:
            return "Aug";
        case 8:
            return "Sep";
        case 9:
            return "Oct";
        case 10:
            return "Nov";
        case 11:
            return "Dec";
    }
}

export default GetDateDisplay;
