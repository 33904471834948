import AnnualEvent from "../models/AnnualEvent";
import Event from "../models/Event";
import NonWSDCEvent from "../models/NonWSDCEvent";
import WSDCEvent from "../models/WSDCEvent";
import { isSimilarString } from "./TextUtils";

export function GetEventsForWSDCEvents(wsdcEvents: WSDCEvent[]): Event[] {
    let events : Event[] = [];
    let totalEvents = 0;
    let matchedEvents = 0;
    for(let i = 0; i < wsdcEvents.length; i++) {
        const event = wsdcEvents[i];
        const eventMatch = GetEventForWSDCEvent(event);
        events.push(eventMatch);
        totalEvents++;
        if(eventMatch.annualEvent) {
            matchedEvents++;
        }
    }
    console.log(`Matched ${matchedEvents} out of ${totalEvents} WSDC events`);
    return events;
}

function GetEventForWSDCEvent(event: WSDCEvent): Event {
    let eventMatch : Event | undefined = undefined;
    for(let i = 0; i < annualEvents.length; i++) {
        const annualEvent = annualEvents[i];
        if(eventsMatch(event, annualEvent)) {
            if(annualEvent.name === "Swingover")
            {
                console.log(event);
            }
            eventMatch = {
                name: annualEvent.name,
                url: event.url,
                address: event.address,
                city: !isSimilarString(event.city, annualEvent.city) ? event.city : annualEvent.city,
                province: !isSimilarString(event.province, annualEvent.province) ? event.province : annualEvent.province,
                country: !isSimilarString(event.country, annualEvent.country) ? event.country : annualEvent.country,
                startDate: event.startDate,
                endDate: event.endDate,
                eventDirector: event.eventDirector,
                logoUrl: annualEvent.logoUrl,
                annualEvent: annualEvent,
                isTrial: event.isTrial,
                isOnHiatus: event.isOnHiatus
            };
            break;
        }
    }
    if(eventMatch)
    {
        return eventMatch;
    }
    return {
        name: event.name,
        url: event.url,
        address: event.address,
        city: event.city,
        province: event.province,
        country: event.country,
        startDate: event.startDate,
        endDate: event.endDate,
        eventDirector: event.eventDirector,
        logoUrl: "/images/wsdc-logo.jpg",
        annualEvent: undefined,
        isTrial: event.isTrial,
        isOnHiatus: event.isOnHiatus
    };
}

function eventsMatch(event: WSDCEvent, annualEvent: AnnualEvent): boolean {
    const isUs = annualEvent.country.toLocaleLowerCase() === "united states";
    const normalizedName = event.name.toLowerCase().trim();
    const normalizedAnnualName = annualEvent.name.toLowerCase().trim();
    const countryMatch =
        annualEvent.country.toLocaleLowerCase() === event.country.toLocaleLowerCase();
    const provinceMatch =
        annualEvent.province.toLocaleLowerCase() === event.province.toLocaleLowerCase();
    const geoMatch = isUs ? countryMatch && provinceMatch : countryMatch;
    if(annualEvent.nameMatches) {
        let matched = false;
        annualEvent.nameMatches.forEach(nameMatch => {
            if(normalizedName.indexOf(nameMatch.toLowerCase().trim()) !== -1)
            {
                matched = true;
            }
        });
        if(matched)
        {
            return true;
        }
    }
    return normalizedName === normalizedAnnualName
    || (geoMatch && isSimilarString(normalizedName, normalizedAnnualName));
}

export function GetEventsForNonWSDCEvents(nonWsdcEvents: NonWSDCEvent[]): Event[] {
    let events : Event[] = [];
    let totalEvents = 0;
    let matchedEvents = 0;
    for(let i = 0; i < nonWsdcEvents.length; i++) {
        const event = nonWsdcEvents[i];
        const eventMatch = GetEventForNonWSDCEvent(event);
        events.push(eventMatch);
        totalEvents++;
        if(eventMatch.annualEvent) {
            matchedEvents++;
        }
    }
    console.log(`Matched ${matchedEvents} out of ${totalEvents} non-WSDC events`);
    return events;
}

function GetEventForNonWSDCEvent(event: NonWSDCEvent): Event {
    let eventMatch : Event | undefined = undefined;
    for(let i = 0; i < annualEvents.length; i++) {
        const annualEvent = annualEvents[i];
        const normalizedName = event.name.toLowerCase().trim();
        const normalizedAnnualName = annualEvent.name.toLowerCase().trim();
        if(normalizedName === normalizedAnnualName) {
            eventMatch = {
                name: annualEvent.name,
                url: event.url,
                address: event.city,
                city: event.city ? event.city : annualEvent.city,
                province: event.province ? event.province : annualEvent.province,
                country: event.country ? event.country : annualEvent.country,
                startDate: event.startDate ? new Date(event.startDate) : undefined,
                endDate: event.endDate ? new Date(event.endDate) : undefined,
                eventDirector: event.eventDirector,
                logoUrl: annualEvent.logoUrl,
                annualEvent: annualEvent,
                isTrial: false,
                isOnHiatus: false
        
            };
            break;
        }
    }
    if(eventMatch)
    {
        return eventMatch;
    }
    return {
        name: event.name,
        url: event.url,
        address: event.address,
        city: event.city,
        province: event.province,
        country: event.country,
        startDate: event.startDate ? new Date(event.startDate) : undefined,
        endDate: event.endDate ? new Date(event.endDate) : undefined,
        eventDirector: event.eventDirector,
        logoUrl: "/images/suitcase-dancer-512.jpg",
        annualEvent: undefined,
        isTrial: false,
        isOnHiatus: false
    };
}

const annualEvents : AnnualEvent[] = [
    {
        name: "5280 Westival",
        url: "https://5280westival.com/",
        city: "Denver",
        province: "Colorado",
        country: "United States",
        eventDirector: "",
        logoUrl: "/logos/us/5280-westival-logo.jpg"
    },
    {
        name: "The After Party",
        nameMatches: ["The After Party"],
        url: "https://tapwcs.com/",
        city: "Irvine",
        province: "California",
        country: "United States",
        eventDirector: "",
        logoUrl: "/logos/us/tap-logo.jpg"
    },
    {
        name: "All Star SwingJam",
        url: "https://www.allstarswingjam.com/",
        city: "San Rafael",
        province: "California",
        country: "United States",
        eventDirector: "",
        logoUrl: "/logos/us/all-star-swingjam-logo.jpg"
    },
    {
        name: "The Aloha open",
        url: "https://www.alohaopenwcs.com/",
        city: "Maui",
        province: "Hawaii",
        country: "United States",
        eventDirector: "",
        logoUrl: "/logos/us/aloha-open-logo.jpg"
    },
    {
        name: "Americano Dance Camp",
        url: "https://vk.com/americano2024",
        city: "Samara",
        province: "Samara",
        country: "Russia",
        eventDirector: "",
        logoUrl: "/logos/asia/americano-dance-camp-logo.jpg"
    },
    {
        name: "Anchor Festival",
        url: "https://www.facebook.com/AnchorFestivalHamburg",
        city: "Hamburg",
        province: "Hamburg",
        country: "Germany",
        eventDirector: "",
        logoUrl: "/logos/europe/anchor-festival-logo.jpg"
    },
    {
        name: "Arizona Dance Classic",
        url: "http://www.arizonadanceclassic.com/",
        city: "Phoenix",
        province: "Arizona",
        country: "United States",
        eventDirector: "",
        logoUrl: "/logos/us/arizona-dance-classic-logo.jpg"
    },
    {
        name: "Arousa Westie Fest",
        url: "http://arousawestiefest.com/",
        city: "Sanxenxo",
        province: "Sanxenxo",
        country: "Spain",
        eventDirector: "",
        logoUrl: "/logos/europe/arousa-westie-fest-logo.jpg"
    },
    {
        name: "Atlanta Swing Classic",
        url: "https://www.atlantaswingclassic.com/",
        city: "Atlanta",
        province: "Georgia",
        country: "United States",
        eventDirector: "Alan Anicella",
        logoUrl: "/logos/us/atlanta-swing-classic-logo.jpg"
    },
    {
        name: "Ausburg Westie Station",
        url: "https://www.augsburgwestiestation.com/",
        city: "Ausburg",
        province: "Bavaria",
        country: "Germany",
        eventDirector: "",
        logoUrl: "/logos/europe/ausburg-westie-station-logo.jpg"
    },
    {
        name: "Austin Rocks",
        url: "http://austinrocksyou.com/",
        city: "Austin",
        province: "Texas",
        country: "United States",
        eventDirector: "",
        logoUrl: "/logos/us/austin-rocks-logo.jpg"
    },
    {
        name: "Austin Swing Dance Championships",
        url: "https://austinswingdance.com/",
        city: "Austin",
        province: "Texas",
        country: "United States",
        eventDirector: "",
        logoUrl: "/logos/us/austin-swing-dance-championships-logo.jpg"
    },
    {
        name: "Autumn Swing Challenge",
        url: "https://www.asc-budapest.com/",
        city: "Budapest",
        province: "Budapest",
        country: "Hungary",
        eventDirector: "",
        logoUrl: "/logos/europe/autumn-swing-challenge-logo.jpg"
    },
    {
        name: "Baltic Swing",
        url: "http://www.balticswing.com/",
        city: "Gdansk",
        province: "Pomorskie",
        country: "Poland",
        eventDirector: "",
        logoUrl: "/logos/europe/baltic-swing-logo.jpg"
    },
    {
        name: "Bama Jamma",
        url: "https://bamajamma.com/",
        city: "Birmingham",
        province: "Alabama",
        country: "United States",
        eventDirector: "",
        logoUrl: "/logos/us/bama-jamma-logo.jpg"
    },
    {
        name: "Bavarian Open West Coast Swing Championships",
        url: "https://bavarianopen.com/",
        city: "Munich",
        province: "Bavaria",
        country: "Germany",
        eventDirector: "",
        logoUrl: "/logos/europe/bavarian-open-logo.jpg"
    },
    {
        name: "Best of the Best",
        url: "http://bestofthebestwcs.com/",
        city: "Ashfield",
        province: "New South Wales",
        country: "Australia",
        eventDirector: "",
        logoUrl: "/logos/oceania/best-of-the-best-logo.jpg"
    },
    {
        name: "BeeMAD",
        url: "https://beemadwcs.com/",
        city: "Madrid",
        province: "Spain",
        country: "Spain",
        eventDirector: "",
        logoUrl: "/logos/europe/bee-mad-logo.jpg"
    },
    {
        name: "The Bend Connection",
        url: "https://thebendconnection.com/",
        city: "Bend",
        province: "Oregon",
        country: "United States",
        eventDirector: "",
        logoUrl: "/logos/us/bend-connection-logo.jpg"
    },
    {
        name: "Berlin Swing Revolution",
        url: "https://berlinswingrevolution.com/",
        city: "Berlin",
        province: "Berlin",
        country: "Germany",
        eventDirector: "",
        logoUrl: "/logos/europe/berlin-swing-revolution-logo.jpg"
    },
    {
        name: "Best of the Best WCS",
        url: "https://www.bestofthebestwcs.com/",
        city: "Ashfield",
        province: "New South Wales",
        country: "Australia",
        eventDirector: "",
        logoUrl: "/logos/oceania/best-of-the-best-logo.jpg"
    },
    {
        name: "Big Apple Dance Festival",
        url: "http://bigapplecountrydance.com/",
        city: "New York",
        province: "New York",
        country: "United States",
        eventDirector: "",
        logoUrl: "/logos/us/big-apple-dance-festival-logo.jpg"
    },
    {
        name: "Boogie by the Bay",
        url: "https://boogiebythebay.com/",
        city: "Burlingame",
        province: "Valifornia",
        country: "United States",
        eventDirector: "",
        logoUrl: "/logos/us/boogie-by-the-bay-logo.jpg"
    },
    {
        name: "The Boston Tea Party",
        url: "http://teapartyswings.com/",
        city: "Boston",
        province: "Massachusetts",
        country: "United States",
        eventDirector: "",
        logoUrl: "/logos/us/boston-tea-party-logo.jpg"
    },
    {
        name: "Bridgetown Swing",
        url: "http://www.portlandswing.org/bts/",
        city: "Vancouver",
        province: "Washington",
        country: "United States",
        eventDirector: "",
        logoUrl: "/logos/us/bridgetown-swing-logo.jpg"
    },
    {
        name: "Bristol Swing Fiesta",
        url: "https://www.bristolcityswing.com/bsf",
        city: "Bristol",
        province: "Bristol",
        country: "United Kingdom",
        eventDirector: "",
        logoUrl: "/logos/europe/bristol-swing-fiesta-logo.jpg"
    },
    {
        name: "BTO Open",
        nameMatches: ["By-Town Open"],
        url: "http://btoopen.ca/",
        city: "Ottawa",
        province: "Ontario",
        country: "Canada",
        eventDirector: "",
        logoUrl: "/logos/na/bto-open-logo.jpg"
    },
    {
        name: "BudaFest",
        nameMatches: ["BudaFest"],
        url: "https://wcs-budafest.com/",
        city: "Budapest",
        province: "",
        country: "Hungary",
        eventDirector: "",
        logoUrl: "/logos/europe/budafest-logo.jpg"
    },
    {
        name: "Calgary Dance Stampede",
        url: "https://www.calgarydancestampede.com/",
        city: "Calgary",
        province: "Alberta",
        country: "Canada",
        eventDirector: "",
        logoUrl: "/logos/na/calgary-dance-stampede-logo.jpg"
    },
    {
        name: "Canadian Swing Championships",
        url: "http://www.canadianswingchampionships.com/",
        city: "Sherbrooke",
        province: "Quebec",
        country: "Canada",
        eventDirector: "",
        logoUrl: "/logos/na/canadian-swing-championships-logo.jpg"
    },
    {
        name: "Capital Swing Convention",
        url: "http://capitalswingconvention.com/",
        city: "Sacramento",
        province: "California",
        country: "United States",
        eventDirector: "",
        logoUrl: "/logos/us/capital-swing-convention-logo.jpg"
    },
    {
        name: "CASH Bash",
        url: "https://cashdanceclub.org/",
        city: "Twinsburg",
        province: "Ohio",
        country: "United States",
        eventDirector: "",
        logoUrl: "/logos/us/cash-bash-logo.jpg"
    },
    {
        name: "Charlotte Westie Fest",
        url: "https://www.charlottewestiefest.com/",
        city: "Charlotte",
        province: "North Carolina",
        country: "United States",
        eventDirector: "",
        logoUrl: "/logos/us/charlotte-westie-fest-logo.jpg"
    },
    {
        name: "The Chicago Classic",
        url: "https://thechicagoclassic.com/",
        city: "Chicago",
        province: "Illinois",
        country: "United States",
        eventDirector: "",
        logoUrl: "/logos/us/chicago-classic-logo.jpg"
    },
    {
        name: "Chicagoland Country and Swing Dance Festival",
        url: "https://chicagolanddancefestival.com/",
        city: "Chicago",
        province: "Illinois",
        country: "United States",
        eventDirector: "",
        logoUrl: "/logos/us/chicagoland-logo.gif"
    },
    {
        name: "City of Angels Swing Event",
        url: "https://www.cityofangelsswing.com/",
        city: "Los Angeles",
        province: "California",
        country: "United States",
        eventDirector: "",
        logoUrl: "/logos/us/city-of-angels-logo.jpg"
    },
    {
        name: "Colorado Country Classic",
        url: "http://coloradocountryclassic.net/",
        city: "Denver",
        province: "Colorado",
        country: "United States",
        eventDirector: "",
        logoUrl: "/logos/us/colorado-country-classic-logo.jpg"
    },
    {
        name: "Countdown Swing Boston",
        url: "https://countdownswingboston.com/",
        city: "Mansfield",
        province: "Massachusetts",
        country: "United States",
        eventDirector: "",
        logoUrl: "/logos/us/countdown-swing-boston-logo.jpg"
    },
    {
        name: "D-Town Swing",
        url: "https://www.d-townswing.com/",
        city: "Dusseldorf",
        province: "Dusseldorf",
        country: "Germany",
        eventDirector: "",
        logoUrl: "/logos/europe/d-townswing-logo.jpg"
    },
    {
        name: "Dance Mardi Gras",
        url: "http://www.dancemardigras.com/",
        city: "New Orleans",
        province: "Louisiana",
        country: "United States",
        eventDirector: "",
        logoUrl: "/logos/us/dance-mardi-gras-logo.jpg"
    },
    {
        name: "Dance N Play",
        url: "https://dancenplay.com/",
        city: "Redmond",
        province: "Oregon",
        country: "United States",
        eventDirector: "",
        logoUrl: "/logos/us/dance-n-play-logo.jpg"
    },
    {
        name: "DC Swing eXperience",
        url: "https://dcswingexperience.com/",
        city: "Herndon",
        province: "Virginia",
        country: "United States",
        eventDirector: "",
        logoUrl: "/logos/us/dc-swing-experience-logo.jpg"
    },
    {
        name: "Derby City Swing",
        url: "https://derbycityswing.com/",
        city: "Louisville",
        province: "Kentucky",
        country: "United States",
        eventDirector: "",
        logoUrl: "/logos/us/derby-city-swing-logo.jpg"
    },
    {
        name: "Desert City Swing",
        url: "http://www.desertcityswing.com/",
        city: "Phoenix",
        province: "Arizona",
        country: "United States",
        eventDirector: "",
        logoUrl: "/logos/us/desert-city-swing-logo.jpg"
    },
    {
        name: "Detonation Dance",
        url: "https://detonationdance.com/",
        city: "Manchester",
        province: "Manchester",
        country: "United Kingdom",
        eventDirector: "",
        logoUrl: "/logos/europe/detonation-dance-logo.jpg"
    },
    {
        name: "Dutch Open WCS",
        nameMatches: ["Dutch Open West Coast Swing"],
        url: "https://www.dutchopenwcs.com/",
        city: "Venray",
        province: "Limburg",
        country: "Netherlands",
        eventDirector: "",
        logoUrl: "/logos/europe/dutch-open-wcs-logo.jpg"
    },
    {
        name: "Easter Swing",
        url: "https://www.easterswing.org/",
        city: "Bellevue",
        province: "Washington",
        country: "United States",
        eventDirector: "",
        logoUrl: "/logos/us/easter-swing-logo.jpg"
    },
    {
        name: "ESS Camp",
        url: "https://www.esscamp.com/",
        city: "Phoenix",
        province: "Arizona",
        country: "United States",
        eventDirector: "",
        logoUrl: "/logos/us/ess-camp-logo.jpg"
    },
    {
        name: "Euro Dance Festival",
        url: "https://www.euro-dance-festival.com/rust/",
        city: "Rust",
        province: "Rust",
        country: "Germany",
        eventDirector: "",
        logoUrl: "/logos/europe/euro-dance-festival-logo.jpg"
    },
    {
        name: "European Swing Challenge",
        url: "https://www.jiveaddiction.com/",
        city: "Nottingham",
        province: "Nottingham",
        country: "United Kingdom",
        eventDirector: "",
        logoUrl: "/logos/europe/european-swing-challenge-logo.jpg"
    },
    {
        name: "Finnfest",
        url: "https://finnfest.fi/",
        city: "Helsinki",
        province: "Uusimaa",
        country: "Finland",
        eventDirector: "",
        logoUrl: "/logos/europe/finnfest-logo.jpg"
    },
    {
        name: "Florida Dance Magic",
        url: "http://floridadancemagic.com/",
        city: "West Palm Beach",
        province: "Florida",
        country: "United States",
        eventDirector: "",
        logoUrl: "/logos/us/florida-dance-magic-logo.jpg"
    },
    {
        name: "Floorplay Swing Vacation",
        url: "https://floorplayswingvacation.com/",
        city: "Orlando",
        province: "Florida",
        country: "United States",
        eventDirector: "",
        logoUrl: "/logos/us/floorplay-swing-vacation-logo.jpg"
    },
    {
        name: "Freedom Swing Dance Challenge",
        url: "https://freedomswingdance.com/",
        city: "Wilmington",
        province: "Delaware",
        country: "United States",
        eventDirector: "",
        logoUrl: "/logos/us/freedom-swing-dance-challenge-logo.jpg"
    },
    {
        name: "French Open WCS",
        url: "http://www.fowcs.com/",
        city: "Paris",
        province: "Paris",
        country: "France",
        eventDirector: "",
        logoUrl: "/logos/europe/french-open-logo.jpg"
    },
    {
        name: "The German Open WCS Championships",
        url: "http://go-wcs.com/",
        city: "Freiburg",
        province: "Baden-Württemberg",
        country: "Germany",
        eventDirector: "",
        logoUrl: "/logos/europe/german-open-championships-logo.jpg"
    },
    {
        name: "Global Grand Prix",
        nameMatches: ["Global Grand Prix"],
        url: "https://www.globalgrandprixwcs.com/",
        city: "Toulouse",
        province: "Haute Garonne",
        country: "France",
        eventDirector: "",
        logoUrl: "/logos/europe/global-grand-prix-logo.jpg"
    },
    {
        name: "Go West Swing Fest",
        url: "https://www.facebook.com/GoWestSwingfest/",
        city: "",
        province: "",
        country: "Australia",
        eventDirector: "",
        logoUrl: "/logos/oceania/go-west-swing-fest-logo.jpg"
    },
    {
        name: "Halloween Swingthing",
        url: "https://www.highdesertdanceclassic.com/",
        city: "Santa Ana",
        province: "California",
        country: "United States",
        eventDirector: "",
        logoUrl: "/logos/us/halloween-swingthing-logo.jpg"
    },
    {
        name: "High Desert Dance Classic",
        url: "https://www.highdesertdanceclassic.com/",
        city: "Lancaster",
        province: "California",
        country: "United States",
        eventDirector: "",
        logoUrl: "/logos/us/high-desert-dance-classic-logo.jpg"
    },
    {
        name: "Honey Fest",
        url: "https://honeyfest-ufa.ru/",
        city: "Ufa",
        province: "Ufa",
        country: "Russia",
        eventDirector: "",
        logoUrl: "/logos/asia/honey-fest-logo.jpg"
    },
    {
        name: "Hungarian Open",
        url: "http://wcs-ho.com/",
        city: "Budapest",
        province: "Budapest",
        country: "Hungary",
        eventDirector: "",
        logoUrl: "/logos/europe/hungarian-open-logo.jpg"
    },
    {
        name: "Indy Dance Explosion",
        url: "http://www.indydancex.com/",
        city: "Indianapolis",
        province: "Indiana",
        country: "United States",
        eventDirector: "",
        logoUrl: "/logos/us/indy-dance-explosion-logo.jpg"
    },
    {
        name: "Jack & Jill O'Rama",
        url: "https://jackandjillorama.com/",
        city: "Garden Grove",
        province: "California",
        country: "United States",
        eventDirector: "",
        logoUrl: "/logos/us/jack-and-jill-orama-logo.jpg"
    },
    {
        name: "Jax Westie Fest",
        url: "https://jaxwestiefest.com/",
        city: "Jacksonville",
        province: "Florida",
        country: "United States",
        eventDirector: "",
        logoUrl: "/logos/us/jax-westie-fest-logo.jpg"
    },
    {
        name: "King Swing",
        url: "http://kingswing.pl/",
        city: "Krakow",
        province: "Malopolska",
        country: "Poland",
        eventDirector: "",
        logoUrl: "/logos/europe/king-swing-logo.jpg"
    },
    {
        name: "Kiwi Fest",
        url: "https://kiwifest.info/",
        city: "Kyiv",
        province: "Kyiv",
        country: "Ukraine",
        eventDirector: "",
        logoUrl: "/logos/europe/kiwi-fest-logo.jpg"
    },
    {
        name: "Korea Westival",
        url: "https://www.koreawestival.com/",
        city: "Mapo",
        province: "Seoul",
        country: "South Korea",
        eventDirector: "",
        logoUrl: "/logos/asia/korea-westival-logo.jpg"
    },
    {
        name: "Korean Open",
        nameMatches: ["Korean Open"],
        url: "https://www.kopenwcs.com/",
        city: "Incheon",
        province: "",
        country: "South Korea",
        eventDirector: "",
        logoUrl: "/logos/asia/korean-open-logo.jpg"
    },
    {
        name: "Liberty Swing Dance Championships",
        url: "http://www.libertyswing.com/",
        city: "New Brunswick",
        province: "New Jersey",
        country: "United States",
        eventDirector: "",
        logoUrl: "/logos/us/liberty-swing-dance-championships-logo.jpg"
    },
    {
        name: "LoneStar Invitational",
        url: "http://www.lonestarcountrydance.com/",
        city: "Austin",
        province: "Texas",
        country: "United States",
        eventDirector: "",
        logoUrl: "/logos/us/lone-star-invitational-logo.jpg"
    },
    {
        name: "MADJam",
        nameMatches: ["MADJam"],
        url: "https://www.atlanticdancejam.com/",
        city: "Reston",
        province: "Virginia",
        country: "United States",
        eventDirector: "Kay Newhouse",
        logoUrl: "/logos/us/madjam-logo.jpg"
    },
    {
        name: "Meet Me In St. Louis Swing Dance Championships",
        url: "https://www.mmisl.com/",
        city: "Defiance",
        province: "Missouri",
        country: "United States",
        eventDirector: "",
        // TODO: Logo is for 2022
        logoUrl: "/logos/us/meet-me-in-st-louis-logo.jpg"
    },
    {
        name: "Michigan Classic",
        url: "http://www.michiganclassicswing.com/",
        city: "Romulus",
        province: "Michigan",
        country: "United States",
        eventDirector: "",
        logoUrl: "/logos/us/michigan-classic-logo.jpg"
    },
    {
        name: "Midland Swing Open",
        nameMatches: ["Midland Swing Open"],
        url: "https://www.midlandswingopen.com/",
        city: "Longford",
        province: "Hillingdon",
        country: "United Kingdom",
        eventDirector: "",
        logoUrl: "/logos/europe/midland-swing-open-logo.jpg"
    },
    {
        name: "Midnight Madness",
        nameMatches: ["Midnight Madness"],
        url: "https://dallasdancefestival.com/midnight-madness",
        city: "Irving",
        province: "Texas",
        country: "United States",
        eventDirector: "",
        logoUrl: "/logos/us/midnight-madness-logo.jpg"
    },
    {
        name: "Midwest Westie Fest",
        url: "http://midwestwestiefest.com/",
        city: "Overland Park",
        province: "Kansas",
        country: "United States",
        eventDirector: "",
        logoUrl: "/logos/us/midwest-westie-fest-logo.jpg"
    },
    {
        name: "Milan Modern Swing",
        url: "https://www.westcoastswingmilan.com/",
        city: "Milan",
        province: "Milan",
        country: "Italy",
        eventDirector: "",
        logoUrl: "/logos/europe/milan-modern-swing-logo.jpg"
    },
    {
        name: "Montreal Westie Fest",
        url: "http://montrealwestiefest.com/",
        city: "Montreal",
        province: "Quebec",
        country: "Canada",
        eventDirector: "",
        logoUrl: "/logos/na/montreal-westie-fest-logo.jpg"
    },
    {
        name: "Mooseland Swing",
        url: "https://mooselandswing.com/",
        city: "Östersund",
        province: "",
        country: "Sweden",
        eventDirector: "",
        // TODO - trial event in logo
        logoUrl: "/logos/europe/mooseland-swing-logo.jpg"
    },
    {
        name: "Mountain Magic Dance Convention",
        url: "https://www.michelledance.com/mmm.html",
        city: "South Lake Tahoe",
        province: "Nevada",
        country: "United States",
        eventDirector: "",
        logoUrl: "/logos/us/mountain-magic-dance-convention-logo.jpg"
    },
    {
        name: "Neverland Swing",
        url: "http://www.neverlandswing.com/",
        city: "",
        province: "",
        country: "Netherlands",
        eventDirector: "",
        logoUrl: "/logos/europe/neverland-swing-logo.jpg"
    },
    {
        name: "New England Dance Festival",
        url: "http://www.nedancefestival.com/",
        city: "Newton",
        province: "Massachusetts",
        country: "United States",
        eventDirector: "",
        logoUrl: "/logos/us/new-england-dance-festival-logo.jpg"
    },
    {
        name: "New Zealand Open",
        url: "https://www.streetswing.co.nz/the-new-zealand-open",
        city: "Auckland",
        province: "Auckland",
        country: "New Zealand",
        eventDirector: "",
        logoUrl: "/logos/oceania/new-zealand-open-logo.jpg"
    },
    {
        name: "Nordic WCS Championships",
        url: "https://www.nordicwcschamps.com/",
        city: "Stockholm",
        province: "Upplands Väsby",
        country: "Sweden",
        eventDirector: "",
        logoUrl: "/logos/europe/nordic-wcs-championships-logo.jpg"
    },
    {
        name: "Norway Westie Fest",
        url: "https://www.nordicwcschamps.com/",
        city: "Stockholm",
        province: "Upplands Väsby",
        country: "Sweden",
        eventDirector: "",
        logoUrl: "/logos/europe/nordic-wcs-championships-logo.jpg"
    },
    {
        name: "Novice Invitational",
        url: "http://www.novice-invitational.com/",
        city: "Houston",
        province: "Texas",
        country: "United States",
        eventDirector: "",
        logoUrl: "/logos/us/novice-invitational-logo.jpg"
    },
    {
        name: "Odyssey West Coast Swing",
        url: "http://www.odysseywcs.dance/",
        city: "Nerang",
        province: "Queensland",
        country: "Australia",
        eventDirector: "",
        logoUrl: "/logos/oceania/odyssey-west-coast-swing-logo.jpg"
    },
    {
        name: "Orange Blossom Dance Festival",
        url: "http://www.orangeblossomdance.net/",
        city: "Orlando",
        province: "Florida",
        country: "United States",
        eventDirector: "",
        logoUrl: "/logos/us/orange-blossom-logo.jpg"
    },
    {
        name: "Paradise Dance Festival",
        url: "https://paradisecountrydancefestival.com/",
        city: "Irvine",
        province: "California",
        country: "United States",
        eventDirector: "",
        logoUrl: "/logos/us/paradise-dance-festival-logo.jpg"
    },
    {
        name: "Philly Swing Dance Classic",
        url: "https://phillyswing.com/",
        city: "Wilmington",
        province: "Delaware",
        country: "United States",
        eventDirector: "",
        logoUrl: "/logos/us/philly-swing-dance-classic-logo.jpg"
    },
    {
        name: "Phoenix 4th of July",
        url: "http://phoenix4thofjuly.com/",
        city: "Phoenix",
        province: "Arizona",
        country: "United States",
        eventDirector: "",
        logoUrl: "/logos/us/phoenix-4th-of-july-logo.jpg"
    },
    {
        name: "ProAm in Paradise",
        url: "https://pensacolaswingdancesociety.com/proam-in-paradise",
        city: "Pensacola",
        province: "Florida",
        country: "United States",
        eventDirector: "Justin Grubbs",
        logoUrl: "/logos/us/proam-in-paradise-logo.jpg"
    },
    {
        name: "Retaliation Swing",
        url: "https://retaliationswing.com/",
        city: "Seattle",
        province: "Washington",
        country: "United States",
        eventDirector: "",
        logoUrl: "/logos/us/retaliation-swing-logo.jpg"
    },
    {
        name: "Riga Summer Swing",
        url: "http://rigasummerswing.com/",
        city: "Riga",
        province: "",
        country: "Latvia",
        eventDirector: "",
        // TODO: logo is for 2020
        logoUrl: "/logos/europe/riga-summer-swing-logo.jpg"
    },
    {
        name: "River City Swing",
        url: "http://www.floridadanceevents.com/",
        city: "Ponte Vedra Beach",
        province: "Florida",
        country: "United States",
        eventDirector: "",
        logoUrl: "/logos/us/river-city-swing-logo.jpg"
    },
    {
        name: "Rock the Barn",
        url: "https://rockthebarn.se/",
        city: "Gräsmyr",
        province: "",
        country: "Sweden",
        eventDirector: "",
        logoUrl: "/logos/europe/rock-the-barn-logo.jpg"
    },
    {
        name: "Rocket City Swing",
        url: "https://rocketcityswing.com/",
        city: "Huntsville",
        province: "Alabama",
        country: "United States",
        eventDirector: "",
        logoUrl: "/logos/us/rocket-city-swing-logo.jpg"
    },
    {
        name: "Rolling Swing",
        url: "https://www.frenchywesty.com/",
        city: "Bron",
        province: "Rhone",
        country: "France",
        eventDirector: "",
        logoUrl: "/logos/europe/rolling-swing-logo.jpg"
    },
    {
        name: "Rose City Swing",
        url: "https://rosecityswing.com/",
        city: "Tigard",
        province: "Oregon",
        country: "United States",
        eventDirector: "",
        logoUrl: "/logos/us/rose-city-swing-logo.jpg"
    },
    {
        name: "SaunaSwing",
        url: "https://ekarolas.com/saunaswing/",
        city: "Ikaalinen",
        province: "Pirkanmaa",
        country: "Finland",
        eventDirector: "",
        logoUrl: "/logos/europe/sauna-swing-logo.jpg"
    },
    {
        name: "Scandinavian Open WCS",
        nameMatches: ["Scandinavian Open"],
        url: "https://snowcs.se/",
        city: "Stockholm",
        province: "Stockholm",
        country: "Sweden",
        eventDirector: "",
        logoUrl: "/logos/europe/scandinavian-open-wcs-logo.jpg"
    },
    {
        name: "Sea Q-Fest",
        url: "https://vk.com/swing_q_fest",
        city: "Anapa",
        province: "Southern Federal District",
        country: "Russia",
        eventDirector: "",
        logoUrl: "/logos/asia/sea-q-fest-logo.jpg"
    },
    {
        name: "Sea To Sky Seattle",
        url: "https://www.seatoskywcs.com/",
        city: "Seattle",
        province: "Washington",
        country: "United States",
        eventDirector: "",
        logoUrl: "/logos/us/sea-to-sky-logo.jpg"
    },
    {
        name: "Sea Sun & Swing Camp",
        url: "https://seasunswing.fr/",
        city: "La Grande-Motte",
        province: "",
        country: "France",
        eventDirector: "",
        logoUrl: "/logos/europe/sea-sun-swing-camp-logo.jpg"
    },
    {
        name: "Shakedown Swing",
        url: "https://code03swing.com/shakedown",
        city: "Christchurch",
        province: "Canterbury",
        country: "New Zealand",
        eventDirector: "",
        logoUrl: "/logos/oceania/shakedown-swing-logo.jpg"
    },
    {
        name: "Show Me Showdown",
        url: "https://theshowmeshowdown.com/",
        city: "St. Louis",
        province: "Missouri",
        country: "United States",
        eventDirector: "",
        logoUrl: "/logos/us/show-me-showdown-logo.jpg"
    },
    {
        name: "Simply Adelaide West Coast Swing",
        url: "https://www.simplyadelaidewcs.com.au/",
        city: "Adelaide",
        province: "Adelaide",
        country: "Australia",
        eventDirector: "",
        logoUrl: "/logos/oceania/simply-adelaide-west-coast-swing-logo.jpg"
    },
    {
        name: "Spotlight New Year's Celebration",
        url: "https://www.spotlightnewyears.com/",
        city: "Nashville",
        province: "Tennessee",
        country: "United States",
        eventDirector: "",
        logoUrl: "/logos/us/spotlight-new-years-celebration-logo.jpg"
    },
    {
        name: "St. Petersburg WCS Nights",
        url: "http://wcsnights.ru/",
        city: "St. Petersburg",
        province: "St. Petersburg",
        country: "Russia",
        eventDirector: "",
        logoUrl: "/logos/asia/saint-petersburg-wcs-nights-logo.jpg"
    },
    {
        name: "South Bay Dance Fling",
        url: "https://sbdf.dance/",
        city: "San Jose",
        province: "California",
        country: "United States",
        eventDirector: "",
        logoUrl: "/logos/us/south-bay-dance-fling-logo.jpg"
    },
    {
        name: "San Diego Dance Festival",
        url: "https://sandiegodancefestival.com/",
        city: "Irvine",
        province: "California",
        country: "United States",
        eventDirector: "",
        logoUrl: "/logos/us/san-diego-dance-festival-logo.jpg"
    },
    {
        name: "SOSwing",
        url: "http://soswingdance.com/",
        city: "Ashland",
        province: "Oregon",
        country: "United States",
        eventDirector: "",
        logoUrl: "/logos/us/soswing-logo.jpg"
    },
    {
        name: "Spooky Westie Weekend",
        url: "https://spookywestieweekend.com/",
        city: "Singapore",
        province: "Singapore",
        country: "Singapore",
        eventDirector: "",
        logoUrl: "/logos/asia/spooky-westie-weekend-logo.jpg"
    },
    {
        name: "Spring Time Swing",
        url: "https://springtimeswing.com/",
        city: "Freiburg",
        province: "Freiburg",
        country: "Germany",
        eventDirector: "",
        logoUrl: "/logos/europe/spring-time-swing-logo.jpg"
    },
    {
        name: "Slovenian Open",
        url: "https://slovenianopen.dance/",
        city: "Ljubljana",
        province: "Ljubljana",
        country: "Slovenia",
        eventDirector: "",
        logoUrl: "/logos/europe/slovenian-open-logo.jpg"
    },
    {
        name: "Summer Hummer",
        url: "https://summerhummerboston.com/",
        city: "Woburn",
        province: "Massachusetts",
        country: "United States",
        eventDirector: "",
        logoUrl: "/logos/us/summer-hummer-logo.jpg"
    },
    {
        name: "Swing & Snow",
        url: "https://swingandsnow.ru/",
        city: "Saint Petersburg",
        province: "Saint Petersburg",
        country: "Russia",
        eventDirector: "",
        logoUrl: "/logos/asia/swing-and-snow-logo.jpg"
    },
    {
        name: "Swing City Chicago",
        url: "https://swingcitychicago.com/",
        city: "Schaumburg",
        province: "Illinois",
        country: "United States",
        eventDirector: "",
        logoUrl: "/logos/us/swing-city-chicago-logo.jpg"
    },
    {
        name: "Swing Crush",
        url: "https://satxwestiesproductions.com/",
        city: "San Antonio",
        province: "Texas",
        country: "United States",
        eventDirector: "",
        logoUrl: "/logos/us/swing-crush-logo.jpg"
    },
    {
        name: "Swing Dance America",
        url: "https://www.swingdanceamerica.com/",
        city: "Lake Geneva",
        province: "Wisconsin",
        country: "United States",
        eventDirector: "",
        logoUrl: "/logos/us/swing-dance-america-logo.jpg"
    },
    {
        name: "Swing Escape",
        url: "https://www.dancevibe.com.au/",
        city: "Nelson Bay",
        province: "New South Wales",
        country: "Australia",
        eventDirector: "",
        logoUrl: "/logos/oceania/swing-escape-logo.jpg"
    },
    {
        name: "Swing Fiction",
        url: "https://www.swingfiction.cz/",
        city: "Brno",
        province: "Brno",
        country: "Czech Republic",
        eventDirector: "",
        logoUrl: "/logos/europe/swing-fiction-logo.jpg"
    },
    {
        name: "Swing Fling",
        url: "https://swingfling.com/",
        city: "Herndon",
        province: "Virginia",
        country: "United States",
        eventDirector: "",
        logoUrl: "/logos/us/swing-fling-logo.jpg"
    },
    {
        name: "Swing In Capital",
        url: "https://www.westcoastswingroma.it/",
        city: "Roma",
        province: "Roma",
        country: "Italy",
        eventDirector: "",
        logoUrl: "/logos/europe/swing-in-capital-logo.jpg"
    },
    {
        name: "SOM - Swing of Music",
        nameMatches: ["Swing of Music"],
        url: "https://www.swingofmusic.com/",
        city: "Wels",
        province: "Wels",
        country: "Austria",
        eventDirector: "",
        logoUrl: "/logos/europe/swing-of-music-logo.jpg"
    },
    {
        name: "Swing Resolution",
        url: "https://swingresolution.com/",
        city: "Edinburgh",
        province: "",
        country: "Scotland",
        eventDirector: "",
        logoUrl: "/logos/europe/swing-resolution-logo.jpg"
    },
    {
        name: "Swingapalooza",
        url: "http://www.swingapaloozaevent.com/",
        city: "Baton Rouge",
        province: "Louisiana",
        country: "United States",
        eventDirector: "",
        logoUrl: "/logos/us/swingapalooza-logo.jpg"
    },
    {
        name: "Swingcouver",
        url: "https://swingcouver.com/",
        city: "Portland",
        province: "Oregon",
        country: "United States",
        eventDirector: "",
        logoUrl: "/logos/na/swingcouver-logo.jpg"
    },
    {
        name: "Swingin' Into Spring",
        url: "https://www.sis.djkenm.com/",
        city: "Hartford",
        province: "Connecticut",
        country: "United States",
        eventDirector: "",
        logoUrl: "/logos/us/swingin-into-spring-logo.jpg"
    },
    {
        name: "Swingover",
        url: "https://www.swingoverevent.com/",
        city: "Orlando",
        province: "Florida",
        country: "United States",
        eventDirector: "",
        logoUrl: "/logos/us/swingover-logo.jpg"
    },
    {
        name: "Swingsation",
        url: "https://rawconnection.com.au/swingsation/",
        city: "Surfers Paradise",
        province: "Queensland",
        country: "Australia",
        eventDirector: "",
        // TODO: Logo is for 2023
        logoUrl: "/logos/oceania/swingsation-logo.jpg"
    },
    {
        name: "Swingside Invitational",
        url: "https://www.swingside-invitational.com/",
        city: "Liège",
        province: "Liège",
        country: "Belgium",
        eventDirector: "",
        logoUrl: "/logos/europe/swingside-invitational-logo.jpg"
    },
    {
        name: "Swingtacular: the Galactic Open",
        url: "http://dancegeekproductions.art/",
        city: "Burlingame",
        province: "California",
        country: "United States",
        eventDirector: "",
        logoUrl: "/logos/us/swingtacular-the-galactic-open-logo.jpg"
    },
    {
        name: "Swingtimate",
        url: "https://rawconnection.com.au/swingtimate/",
        city: "Southport",
        province: "Queensland",
        country: "Australia",
        eventDirector: "",
        logoUrl: "/logos/oceania/swingtimate-logo.jpg"
    },
    {
        name: "Swingtime Denver",
        url: "http://www.swingtimewcs.com/",
        city: "Denver",
        province: "Colorado",
        country: "United States",
        eventDirector: "",
        logoUrl: "/logos/us/swingtime-denver-logo.jpg"
    },
    {
        name: "Swingtzerland",
        url: "https://www.swingtzerland.com/",
        city: "Zürich",
        province: "",
        country: "Switzerland",
        eventDirector: "",
        logoUrl: "/logos/europe/swingtzerland-logo.jpg"
    },
    {
        name: "Swingvasion",
        url: "https://swingcentral.nz/swingvasion",
        city: "Wellington",
        province: "",
        country: "New Zealand",
        eventDirector: "",
        logoUrl: "/logos/oceania/swingvasion-logo.jpg"
    },
    {
        name: "Swingvester",
        url: "https://www.swingvester.com/",
        city: "Wels",
        province: "",
        country: "Austria",
        eventDirector: "",
        logoUrl: "/logos/europe/swingvester-logo.jpg"
    },
    {
        name: "Swustlicious",
        url: "https://www.swustlicious.com/",
        city: "King of Prussia",
        province: "Pennsylvania",
        country: "United States",
        eventDirector: "",
        logoUrl: "/logos/us/swustlicious-logo.jpg"
    },
    {
        name: "Tampa Bay Classic",
        url: "http://www.tbcwcs.com/",
        city: "Tampa",
        province: "Florida",
        country: "United States",
        eventDirector: "",
        logoUrl: "/logos/us/tampa-bay-classic-logo.jpg"
    },
    {
        name: "Texas Classic",
        url: "http://www.thetexasclassic.com/",
        city: "Austin",
        province: "Texas",
        country: "United States",
        eventDirector: "",
        logoUrl: "/logos/us/texas-classic-logo.jpg"
    },
    {
        name: "TLV Swingfest",
        url: "http://www.tlvswingfest.com/",
        city: "Tel Aviv",
        province: "",
        country: "Israel",
        eventDirector: "",
        logoUrl: "/logos/europe/tlv-swingfest-logo.jpg"
    },
    {
        name: "Toronto Open Swing and Hustle Championships",
        url: "http://www.tlvswingfest.com/",
        city: "Toronto",
        province: "Ontario",
        country: "Canada",
        eventDirector: "",
        logoUrl: "/logos/na/toronto-open-swing-and-hustle-championships-logo.jpg"
    },
    {
        name: "Trilogy Swing",
        url: "https://www.trilogywcs.com/",
        city: "Raleigh",
        province: "North Carolina",
        country: "United States",
        eventDirector: "",
        logoUrl: "/logos/us/trilogy-swing-logo.jpg"
    },
    {
        name: "Tulsa Spring Swing",
        url: "http://www.tulsaspringswing.com/",
        city: "Tulsa",
        province: "Oklahoma",
        country: "United States",
        eventDirector: "",
        logoUrl: "/logos/us/tulsa-spring-swing-logo.jpg"
    },
    {
        name: "UCWDC Country Dance World Championships",
        nameMatches: ["UCWDC Country Dance World"],
        url: "https://ucwdc.org/worlds-home/",
        city: "Orlando",
        province: "Florida",
        country: "United States",
        eventDirector: "",
        logoUrl: "/logos/us/ucwdc-worlds-logo.jpg"
    },
    {
        name: "UK WCS Championships",
        nameMatches: ["UK West Coast Swing Championships"],
        url: "https://www.ukwcschamps.com/",
        city: "London",
        province: "West Drayton",
        country: "United Kingdom",
        eventDirector: "",
        logoUrl: "/logos/europe/uk-west-coast-swing-championships-logo.jpg"
    },
    {
        name: "Uptown Swing",
        url: "http://www.uptownswing.se/",
        city: "Täby",
        province: "Stockholm",
        country: "Sweden",
        eventDirector: "",
        logoUrl: "/logos/europe/uptown-swing-logo.jpg"
    },
    {
        name: "USA Grand Nationals Dance Championship",
        url: "http://www.usagrandnationals.com/",
        city: "Atlanta",
        province: "Georgia",
        country: "United States",
        eventDirector: "",
        logoUrl: "/logos/us/usa-grand-nationals-dance-championships-logo.jpg"
    },
    {
        name: "The US Open Swing Dance Championships",
        url: "https://theopenswing.com/",
        city: "Burbank",
        province: "California",
        country: "United States",
        eventDirector: "",
        logoUrl: "/logos/us/us-open-logo.jpg"
    },
    {
        name: "Valentine Swing",
        url: "http://www.valentineswing.dance/",
        city: "Rimbo",
        province: "Rimbo",
        country: "Sweden",
        eventDirector: "",
        logoUrl: "/logos/europe/valentine-swing-logo.jpg"
    },
    {
        name: "Warsaw Halloween Swing",
        url: "https://warsawhalloweenswing.com/",
        city: "Warsaw",
        province: "Warsaw",
        country: "Poland",
        eventDirector: "",
        logoUrl: "/logos/europe/warsaw-halloween-swing-logo.jpg"
    },
    {
        name: "WCS Festival",
        url: "https://www.wcs-festival.de/",
        city: "Düsseldorf",
        province: "Düsseldorf",
        country: "Germany",
        eventDirector: "",
        logoUrl: "/logos/europe/wcs-festival-logo.jpg"
    },
    {
        name: "WCS Party",
        url: "https://www.wcsparty.com/",
        city: "Vienna",
        province: "Vienna",
        country: "Austria",
        eventDirector: "",
        logoUrl: "/logos/europe/wcs-party-logo.jpg"
    },
    {
        name: "Westeroz Swing",
        url: "https://www.magneticdance.com.au/westeroz",
        city: "Sydney",
        province: "New South Wales",
        country: "Australia",
        eventDirector: "",
        logoUrl: "/logos/oceania/westeroz-swing-logo.jpg"
    },
    {
        name: "Westie Gala",
        url: "http://www.westiegala.com/",
        city: "Upplands Väsby",
        province: "Stockholm",
        country: "Sweden",
        eventDirector: "",
        logoUrl: "/logos/europe/westie-gala-logo.jpg"
    },
    {
        name: "Westie Pink City",
        url: "https://www.westiepinkcity.fr/",
        city: "Toulouse",
        province: "Toulouse",
        country: "France",
        eventDirector: "",
        logoUrl: "/logos/europe/westie-pink-city-logo.jpg"
    },
    {
        name: "Westie Spring Thing",
        url: "https://westiespringthing.com/",
        city: "Budapest",
        province: "Budapest",
        country: "Hungary",
        eventDirector: "",
        logoUrl: "/logos/europe/westie-spring-thing-logo.jpg"
    },
    {
        name: "Westie's Angels",
        url: "https://www.frenchywesty.com/projects",
        city: "Beynost",
        province: "Rhone",
        country: "France",
        eventDirector: "",
        logoUrl: "/logos/europe/westies-angels-logo.jpg"
    },
    {
        name: "West in Lyon",
        url: "https://www.west-in-lyon.fr/",
        city: "Dardilly",
        province: "Dardilly",
        country: "France",
        eventDirector: "",
        logoUrl: "/logos/europe/west-in-lyon-logo.jpg"
    },
    {
        name: "Westy Nantes",
        url: "https://www.westynantes.com/",
        city: "Nantes",
        province: "Loire Atlantique",
        country: "France",
        eventDirector: "",
        logoUrl: "/logos/europe/westy-nantes-logo.jpg"
    },
    {
        name: "Wild Wild Westie",
        url: "http://www.wildwildwestie.com/",
        city: "Dallas",
        province: "Texas",
        country: "United States",
        eventDirector: "",
        logoUrl: "/logos/us/wild-wild-westie-logo.jpg"
    },
    {
        name: "Winter Coast Swing",
        url: "http://www.wintercoastswing.com/",
        city: "Leppävirta",
        province: "",
        country: "Finland",
        eventDirector: "",
        logoUrl: "/logos/europe/winter-coast-swing-logo.jpg"
    },
    {
        name: "Winter White WCS",
        nameMatches: ["Winter White"],
        url: "https://www.winterwhite.no/",
        city: "Oslo",
        province: "",
        country: "Norway",
        eventDirector: "",
        logoUrl: "/logos/europe/winter-white-logo.jpg"
    },
];